import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { Testimonial as TestimonialT } from '@/types';
import { useTranslation } from 'react-i18next';
import useDarkMode from '@/hooks/useDarkMode';

interface TestimonialProps {
  testimonial: TestimonialT;
  isActive: boolean;
}

const Testimonial: React.FC<TestimonialProps> = ({ testimonial, isActive }) => {
  const {
    content,
    link,
    picture,
    fullName,
    company,
    companyLogo,
    companyLogoDark,
  } = testimonial;
  const { t } = useTranslation();
  const isDarkMode = useDarkMode();

  return (
    <div
      className={classNames(
        'rounded-main border-8 border-primary px-[35px] py-[28px] lg:mr-[50px] transition-opacity',
        'md:px-[70px] md:py-[55px] md:min-h-[485px] md:max-w-[98%] md:mx-auto',
        'xl:px-[100px] xl:py-[70px] xl:min-h-[564px]',
        { 'opacity-20 ': !isActive },
      )}
    >
      <div className="text-xl xl:text-2xl lg:leading-[35px] mb-12 italic whitespace-pre-line">{`"${t(
        content,
      )}"`}</div>
      <div className="flex justify-between lg:items-center gap-8 flex-col md:flex-row">
        <a
          href={`https://www.${link}`}
          className="flex items-center gap-6 group"
          target="_blank"
          rel="noreferrer"
        >
          <GatsbyImage
            image={getImage(picture)}
            alt="picture"
            className="border-[4px] border-black dark:border-white rounded-full w-[40px] h-[40px] lg:w-[80px] lg:h-[80px] group-hover:-translate-x-2 transition-all duration-500"
            imgClassName="rounded-full"
          />
          <div className="font-bold text-primary lg:text-2xl underline underline-offset-4 decoration-primary decoration-4 group-hover:translate-x-2 group-hover:text-primary transition-all duration-500">
            {fullName} - {company}
          </div>
        </a>
        <div>
          {!isDarkMode && companyLogo}
          {isDarkMode && companyLogoDark}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
