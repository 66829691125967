import React from 'react';

import Kiloutou from '@/assets/partners/Kiloutou.svg';
import Artelia from '@/assets/partners/Artelia.svg';
import SaintGobain from '@/assets/partners/Saint-Gobain.svg';
import EDF from '@/assets/partners/EDF.svg';
import MarineNationale from '@/assets/partners/Marine-Nationale.svg';
import AGC from '@/assets/partners/agc_light.svg';
import AGCDark from '@/assets/partners/agc_dark.svg';
import Setec from '@/assets/partners/setec-light.svg';
import SetecDark from '@/assets/partners/setec-dark.svg';
import LaPoste from '@/assets/partners/LaPoste.svg';

const Partner: React.FC = ({ children }) => (
  <div className="w-3/12 mx-auto lg:w-auto lg:mx-0 self-center mb-[25px]">
    {children}
  </div>
);

const Partners: React.FC = () => (
  <div className="flex flex-row justify-between w-full mt-[30px] mb-[25px] md:mb-[40px] flex-wrap xl:flex-nowrap">
    <Partner>
      <AGC className="dark:hidden max-w-[55px] md:max-w-full md:max-h-full" />
      <AGCDark className="hidden dark:block max-w-[55px] md:max-w-full md:max-h-full" />
    </Partner>
    <Partner>
      <Kiloutou className="fill-mainDark dark:fill-backgroundLight w-full md:max-w-full max-h-[23px] md:max-h-full" />
    </Partner>
    <Partner>
      <Artelia className="fill-mainDark dark:fill-backgroundLight w-full md:max-w-full max-h-[23px] md:max-h-full" />
    </Partner>
    <Partner>
      <SaintGobain className="fill-mainDark dark:fill-backgroundLight w-full md:max-w-full max-h-[23px] md:max-h-full" />
    </Partner>
    <Partner>
      <EDF className="fill-mainDark dark:fill-backgroundLight w-full md:max-w-full max-h-[23px] md:max-h-full" />
    </Partner>
    <Partner>
      <LaPoste className="fill-mainDark dark:fill-backgroundLight w-full md:max-w-full max-h-[23px] md:max-h-full" />
    </Partner>
    <Partner>
      <MarineNationale className="fill-mainDark dark:fill-backgroundLight w-full md:max-w-full max-h-[23px] md:max-h-full" />
    </Partner>
    <Partner>
      <Setec className="dark:hidden w-full md:max-w-full md:max-h-full" />
      <SetecDark className="hidden dark:block w-full md:max-w-full md:max-h-full" />
    </Partner>
  </div>
);

export default Partners;
