import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SubContent from '@/components/SubContent';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { tradWithLink } from '@/utils/trad';
import LinkItem from '@/components/LinkItem';
import { EDF_PROJECT, LAPOSTE_PROJECT } from '@/referentials/routes';

const RecentWork: React.FC = () => {
  const { t } = useI18next();
  const data = useStaticQuery(graphql`
    query {
      laposte: file(relativePath: { eq: "laposte.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      edf: file(relativePath: { eq: "edf.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <SubContent className="lg:mt-[272px] mb-[150px]">
      <h2>{t('recentWorksTitle')}</h2>

      {/* La Poste */}
      <p className="mt-10 lg:mt-20 mb-4 subcontent">
        {tradWithLink(
          'recentWorksLaPoste',
          'La Poste',
          'https://www.lapostegroupe.com',
        )}
      </p>
      <Link to={LAPOSTE_PROJECT}>
        <GatsbyImage
          image={getImage(data.laposte)}
          alt="LaPoste"
          className="imageUplift"
        />
      </Link>
      <div className="mt-8 text-center">
        <Link
          to="/realisations/la-poste"
          className="simpleLink text-lg lg:text-xl"
        >
          {t('seeProject')}
        </Link>
      </div>

      {/* EDF */}
      <p className="mt-10 lg:mt-20 mb-4 subcontent">
        {tradWithLink('recentWorksEdf', 'EDF', 'https://www.edf.fr/pulse')}
      </p>
      <Link to={EDF_PROJECT}>
        <GatsbyImage
          image={getImage(data.edf)}
          alt="EDF"
          className="imageUplift"
        />
      </Link>
      <div className="mt-8 text-center">
        <Link to="/realisations/edf" className="simpleLink text-lg lg:text-xl">
          {t('seeProject')}
        </Link>
      </div>

      {/* See more */}
      <LinkItem
        title={t('seeMoreProjects')}
        to="/realisations"
        className="font-heading mt-20"
      />
    </SubContent>
  );
};

export default RecentWork;
