import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import { tradWithColors } from '@/utils/trad';

import Hero from '@/components/Hero';
import LinkItem from '@/components/LinkItem';

const HomeHero = () => {
  const { t } = useTranslation();

  const htmlContent = useMemo(() => tradWithColors(t('homeHeroTitle')), []);

  const data = useStaticQuery(graphql`
    query {
      homeHero: file(relativePath: { eq: "hero_home.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <Hero
      fullHeight
      title={htmlContent}
      subTitle={
        <>
          <div>{t('homeHeroSubTitle1')}</div>
          <div>{t('homeHeroSubTitle2')}</div>
        </>
      }
      url={data?.homeHero?.publicURL}
      displayImage={false}
    >
      <LinkItem
        title={t('homeHeroLink')}
        className="text-xl lg:text-[30px] font-heading mt-10"
        to="/services"
      />
    </Hero>
  );
};

export default HomeHero;
