import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useI18next } from 'gatsby-plugin-react-i18next';
import BigParagraph from '@/components/Text/Big';
import LargeParagraph from '@/components/Text/Large';
import { tradWithColors } from '@/utils/trad';
import Button from '@/components/Button/Colored';

const Reassurance: React.FC = () => {
  const { t } = useI18next();

  return (
    <div className="my-20 lg:my-[400px] lg:max-w-main lg:mx-auto">
      <BigParagraph>{tradWithColors(t('reassuranceIntro'))}</BigParagraph>

      <LargeParagraph className="mt-[150px] lg:mt-[400px]">
        {tradWithColors(t('reassuranceServices'))}
      </LargeParagraph>
      <Link to="/services">
        <Button color="yellow" className={classNames('w-fit mt-5 lg:mt-10')}>
          {t('reassuranceServicesCta')}
        </Button>
      </Link>

      <LargeParagraph className="mt-[150px] lg:mt-[400px]">
        {tradWithColors(t('reassuranceMethodology'))}
      </LargeParagraph>
      <Link to="/methodologie">
        <Button
          color="orange"
          className="w-fit bg-orange dark:bg-orange font-heading mt-5 lg:mt-10"
        >
          {t('reassuranceMethodologyCta')}
        </Button>
      </Link>

      <LargeParagraph className="mt-[150px] lg:mt-[400px] whitespace-pre-line">
        {tradWithColors(t('reassurancePortfolio'))}
      </LargeParagraph>
      <Link to="/realisations">
        <Button
          color="primaryLighter"
          className="w-fit bg-primaryLighter dark:bg-primaryLighter font-heading mt-5 lg:mt-10"
        >
          {t('reassurancePortfolioCta')}
        </Button>
      </Link>
    </div>
  );
};

export default Reassurance;
