import React from 'react';
import classNames from 'classnames';

interface AerialProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const Big: React.FC<AerialProps> = ({ children, className = '' }) => (
  <div
    className={classNames(
      className,
      'font-heading text-4xl tracking-wide',
      'lg:text-[100px] lg:leading-none',
    )}
  >
    {children}
  </div>
);

export default Big;
