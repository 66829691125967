import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.css';

import { tradWithColors } from '@/utils/trad';
import { Artelink, GenieVision, Setec } from '@/referentials/testimonials';
import ArrowComp from '@/components/ArrowComp';

import Testimonial from './Testimonial';

const Testimonials = () => {
  const ref = useRef<Slider>(null);
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  const testimonials = [Artelink(), GenieVision(), Setec()];

  return (
    <div className="my-[200px] w-full lg:max-w-main flex flex-col mx-auto">
      <div className="text-3xl md:text-4xl lg:text-[64px] mb-[40px] lg:mb-[80px] font-heading">
        {tradWithColors(t('testiTitle'))}
      </div>
      <Slider
        afterChange={(i) => setIndex(i)}
        dots={false}
        ref={ref}
        infinite={false}
        speed={750}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              centerMode: false,
            },
          },
        ]}
      >
        {testimonials.map((test, i) => (
          <Testimonial key={i} testimonial={test} isActive={i === index} />
        ))}
      </Slider>
      <div className="flex gap-5 mt-12">
        <ArrowComp
          onClick={() => ref?.current?.slickPrev()}
          disabled={index === 0}
        />
        <ArrowComp
          isNext
          onClick={() => ref?.current?.slickNext()}
          disabled={index === testimonials.length - 1}
        />
      </div>
    </div>
  );
};

export default Testimonials;
