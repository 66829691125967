import React from 'react';
import classNames from 'classnames';

interface LargeProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const Large: React.FC<LargeProps> = ({ children, className = '' }) => (
  <div
    className={classNames(
      className,
      'font-heading text-2xl tracking-wide',
      'lg:text-[64px] lg:leading-[88px]',
    )}
  >
    {children}
  </div>
);

export default Large;
