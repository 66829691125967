import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { CONTACT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import HomeHero from '@/components/Home/HomeHero';
import Partners from '@/components/Partners';
import CtaBlock from '@/components/CtaBlock';
import Testimonials from '@/components/Home/Testimonials';
import Reassurance from '@/components/Home/Reassurance';
import RecentWork from '@/components/Home/RecentWork';

export default function Home({ data }) {
  const { t } = useTranslation();

  return (
    <Layout description={t('description')}>
      <HomeHero />
      <Partners />
      <RecentWork />
      <Reassurance />
      <Testimonials />
      <CtaBlock
        title={t('ctaBlockTitle')}
        to={CONTACT}
        linkTitle={t('ctaBlockLink')}
        emoji={{
          image: getImage(data.chat),
          alt: 'services icon',
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["home", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    chat: file(relativePath: { eq: "chat.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
  }
`;
