import { useState, useEffect, useMemo } from 'react';

export default function useDarkMode(): boolean {
  const [isDarkMode, setIsDarkmode] = useState(
    typeof document !== 'undefined' && document.body.classList.contains('dark'),
  );

  const attrObserver = useMemo(() => {
    if (typeof window === 'undefined') return null;

    return new MutationObserver((mutations) => {
      mutations.forEach((mu) => {
        // If class modification happened
        if (mu.type === 'attributes' && mu.attributeName === 'class') {
          setIsDarkmode(document.body.classList.contains('dark'));
        }
      });
    });
  }, []);

  useEffect(() => {
    attrObserver.observe(document.body, { attributes: true });
    return () => attrObserver.disconnect();
  }, []);

  return isDarkMode;
}
