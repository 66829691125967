import React from 'react';
import classNames from 'classnames';

interface ColoredButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  color: string;
}

const ColoredButton: React.FC<ColoredButtonProps> = ({
  children,
  className = '',
  onClick = () => null,
  color,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className={classNames(
      'transition cursor-pointer text-black rounded-full text-lg px-5 py-3 font-heading hover:-translate-y-[4%]',
      `bg-${color} dark:bg-${color} hover:shadow-${color}`,
      'lg:px-10 lg:py-6 lg:text-2xl',
      className,
    )}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    {children}
  </div>
);

export default ColoredButton;
